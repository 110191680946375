import React from 'react';
import { useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import NewEventSec from '../CommComponent/NewEventSec';
import {Helmet} from "react-helmet";
// import Desclimerpopup from '../Popups/Desclimerpopup';



const Home = () => {

    useEffect(() => {
    document.body.classList.add('HomePage')
    return () => {
        document.body.classList.remove('HomePage')
    }
    }, [])

    // const [desclimershow, setModalShow1] = React.useState(true);

  return (
    <div className="HomeCont" >
    <Helmet>
        <meta name="theme-color" content="#000000" />
        <title>Best Income Tax Lawyer in Chennai | Banusekar.in</title>		
        <meta name="description" content="Searching for best top tax attorney near by? Banusekar is a certified financial, legal, and tax advisor in Chennai. He is one of the top lawyers in Chennai for black money, benami transactions prohibitions."/>
		<meta name="keywords" content="Legal advisor for business, hire legal advisor, best legal advisor near me, lawyer for financial advisor, senior legal advisor, financial advice lawyer, best legal advisor, top lawyers in Chennai, good advocates in Chennai."/>
    </Helmet>

        <section className="HbanSec">
            <div className='hBanItem'>
                <div className='hBanItemImg'><img src={require("../../assets/images/hbanBgImg.jpg")} alt="Banusekar Banner Img"/></div>
                <div className='hBanItemCont'>
                    <Container>
                        <div className='hBanInCont'>
                            <div className='hBIBox BoxOne'>
                                <h1>Solutions in Direct <br/>Taxation and <br/>Allied Economic Laws</h1>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </section>
        <section className='HIntroSec'>
            <div className='HIntroImgdiv'><img src={require("../../assets/images/HIntroImg.jpg")} alt="Banusekar Banner Img"/></div>
            <div className='HIntroContdiv'>
                <Container>
                    <div data-aos="fade-right" data-aos-duration="1000">
                    <h5>About Us</h5>  
                    <h2>Advisory and Litigation <br/>in Taxation and Allied <br/>Economic Laws</h2> 
                    <p>With years of specialized experience in direct tax and other allied economic laws <br/>such as benami transactions prohibition, black money and money laundering, <br/>we assist our clients from advisory to litigation of matters.</p>
                    <Link to={`${process.env.PUBLIC_URL + "/aboutus"}`} className="expolink">Explore</Link></div>
                </Container>
            </div>
        </section>
        <section className='HServiceSec'>
            <Container>
                <div className='HSereHead ComHeadTitles textalcenter mrgnbtm' data-aos="fade-down" data-aos-duration="1000" >
                    <h2>Services</h2> 
                    {/*<p>An Expert in navigating intricate legal and tax complexities with precision, providing tailored solutions supported <br/>by extensive expertise to his clients not only in Chennai but across India.</p>*/}
                </div>
                <div className='HServCont'>
                    <ul className='ServAccUl'>
                        <li>
                            <div className='ServAccUlli'>
                                <div className='AccIcon'><img src={require("../../assets/images/expertise-icon01.png")} alt="Services"/></div>
                                <div className='AccTitle'>
                                    <h6>Services</h6>
                                    <h3>Taxation</h3>
                                </div>  
                                <div className='exbtn'><Link to={`${process.env.PUBLIC_URL + '/taxation'}/`} className="expolink">Explore</Link></div>
                            </div>
                        </li>
                        <li>
                            <div className='ServAccUlli'>
                                <div className='AccIcon'><img src={require("../../assets/images/expertise-icon02.png")} alt="Services"/></div>
                                <div className='AccTitle'>
                                    <h6>Services</h6>
                                    <h3>International Taxation</h3>
                                </div>  
                                <div className='exbtn'><Link to={`${process.env.PUBLIC_URL + '/international-taxation'}`} className="expolink">Explore</Link></div>
                            </div>
                        </li>
                        <li>
                            <div className='ServAccUlli'>
                                <div className='AccIcon'><img src={require("../../assets/images/expertise-icon05.png")} alt="Services"/></div>
                                <div className='AccTitle'>
                                    <h6>Services</h6>
                                    <h3>Transfer Pricing</h3>
                                </div>  
                                <div className='exbtn'><Link to={`${process.env.PUBLIC_URL + '/master-of-transfer'}`} className="expolink">Explore</Link></div>
                            </div>
                        </li>
                        <li>
                            <div className='ServAccUlli'>
                                <div className='AccIcon'><img src={require("../../assets/images/expertise-icon04.png")} alt="Services"/></div>
                                <div className='AccTitle'>
                                    <h6>Services</h6>
                                    <h3>Black Money</h3>
                                </div>  
                                <div className='exbtn'><Link to={`${process.env.PUBLIC_URL + '/blackmoney'}`} className="expolink">Explore</Link></div>
                            </div>
                        </li>
                        <li>
                            <div className='ServAccUlli'>
                                <div className='AccIcon'><img src={require("../../assets/images/expertise-icon03.png")} alt="Services"/></div>
                                <div className='AccTitle'>
                                    <h6>Services</h6>
                                    <h3>Prohibition of Benami <br/>Property Transactions</h3>
                                </div>  
                                <div className='exbtn'><Link to={`${process.env.PUBLIC_URL + '/benami'}/`} className="expolink">Explore</Link></div>
                            </div>
                        </li>
                        <li>
                            <div className='ServAccUlli'>
                                <div className='AccIcon'><img src={require("../../assets/images/expertise-icon06.png")} alt="Services"/></div>
                                <div className='AccTitle'>
                                    <h6>Services</h6>
                                    <h3>Prevention of <br/>Money Laundering</h3>
                                </div>  
                                <div className='exbtn'><Link to={`${process.env.PUBLIC_URL + '/preventionmoney'}/`} className="expolink">Explore</Link></div>
                            </div>
                        </li>
                    </ul>


                    {/*<Accordion defaultActiveKey="0" className='ServAcc'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div className='Accconter'>
                                    <div className='AccIcon'><img src={require("../../assets/images/expertise-icon01.png")} alt="Services"/></div>
                                    <div className='AccTitle'>
                                        <h6>Services</h6>
                                        <h3>Taxation</h3>
                                    </div>                                
                                    <div className='AccPara'><p>The complexities of India’s taxation, rules and interpreting them correctly  demands a seasoned professional. Offers representation in the appellate fora, consultancy services, and well-versed written opinions, ensuring informed and customized solutions for everyone.</p></div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <ul className='caserul'>
                                    <li><small>Appellate Representation</small>
                                        <p>Expert in taxation shines through and master of appellate representation. With decades of experience, helps provide profound insights and strategic guidance, ensuring maximum benefits for clients in various appellate bodies.</p>
                                    </li>
                                    <li><small>Consultancy and Advisory</small>
                                        <p>Offers insightful guidance for individuals and businesses on taxation matters. In-depth knowledge ensures clients enhance tax efficiency, mitigate risks, and take informed financial decisions.</p>
                                    </li>
                                    <li><small>Written Opinions</small>
                                        <p>Through written opinions, provides clear, informed perspectives, leading clients through complex tax matters.</p>
                                    </li>
                                    <li className='exbtn'>
                                        <Link to={`${process.env.PUBLIC_URL + '/taxation'}/`} className="expolink">Explore More</Link>
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <div className='Accconter'>
                                    <div className='AccIcon'><img src={require("../../assets/images/expertise-icon02.png")} alt="Services"/></div>
                                    <div className='AccTitle'>
                                        <h6>Services</h6>
                                        <h3>International taxation</h3>
                                    </div>                                
                                    <div className='AccPara'><p>Cross-border tax matters need strategic precision and authoritative insights. With my expertise in Appellate Representation, Consultancy, and Written Opinion services, the process of resolving complex global tax issues becomes easier.</p></div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <ul className='caserul'>
                                    <li><small>Appellate Representation</small>
                                        <p>Excels in crafting compelling arguments for clients before various appellate bodies.</p>
                                    </li>
                                    <li><small>Consultancy and Advisory</small>
                                        <p>Strategic consultancy services provide insights for cross-border business activities, empowering clients to make informed decisions.</p>
                                    </li>
                                    <li><small>Written Opinions</small>
                                        <p>Written opinions offer clarity on international tax matters, helping clients navigate complex regulations.</p>
                                    </li>
                                    <li className='exbtn'>
                                        <Link to={`${process.env.PUBLIC_URL + '/international-taxation'}`} className="expolink">Explore More</Link>
                                    </li>
                                </ul>
                              
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                <div className='Accconter'>
                                    <div className='AccIcon'><img src={require("../../assets/images/expertise-icon03.png")} alt="Services"/></div>
                                    <div className='AccTitle'>
                                        <h6>Services</h6>
                                        <h3>Prohibition of benami property transactions</h3>
                                    </div>                                
                                    <div className='AccPara'><p>Expert in International Benami Transactions Prohibition with a focus on Representation in Appellate Fora, Consultancy, and Written Opinion Services. One can leverage my legal acumen to safeguard interests and ensure compliance.</p></div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <ul className='caserul'>
                                    <li><small>Appellate Representation</small>
                                        <p>Excels in providing detailed representation for Benami property transactions, navigating complexities with ease.</p>
                                    </li>
                                    <li><small>Consultancy & Advisory</small>
                                        <p>Consultancy & Advisory offer strategic insights to prevent involvement in Benami transactions and mitigate risks.</p>
                                    </li>
                                    <li><small>Written Opinion</small>
                                        <p>Written opinions provide concise solutions for Benami transactions.</p>
                                    </li>
                                    <li className='exbtn'>
                                        <Link to={`${process.env.PUBLIC_URL + '/benami'}/`} className="expolink">Explore More</Link>
                                    </li>
                          
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                <div className='Accconter'>
                                    <div className='AccIcon'><img src={require("../../assets/images/expertise-icon04.png")} alt="Services"/></div>
                                    <div className='AccTitle'>
                                        <h6>Services</h6>
                                        <h3>Black money & <br/>allied economic laws</h3>
                                    </div>                                
                                    <div className='AccPara'><p>Expert representation, strategic consultancy, and informed opinions on Black Money and Economic Laws unlock favorable outcomes with precision and ethical excellence in taxation matters.</p></div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <ul className='caserul'>
                                    <li><small>Appellate Representation</small>
                                        <p>Ensures clients' interests in Black Money cases are protected by, offering robust defense and compliance with legal regulations.</p>
                                    </li>
                                    <li><small>Consultancy & Advisory</small>
                                        <p>Consultancy services provide strategic solutions in the complex landscape of Black Money and allied laws.</p>
                                    </li>
                                    <li><small>Written Opinion</small>
                                        <p>Written opinions provide clear and complete guidance on compliance and risks related to Black Money and allied laws.</p>
                                    </li>
                                    <li className='exbtn'>
                                        <Link to={`${process.env.PUBLIC_URL + '/blackmoney'}`} className="expolink">Explore More</Link>
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>
                                <div className='Accconter'>
                                    <div className='AccIcon'><img src={require("../../assets/images/expertise-icon05.png")} alt="Services"/></div>
                                    <div className='AccTitle'>
                                        <h6>Services</h6>
                                        <h3>Transfer pricing</h3>
                                    </div>                                
                                    <div className='AccPara'><p>Expertise in complying with Transfer Pricing matters through meticulous documentation and expert representation. Shielding oneself from penalties, ensuring success in transfer pricing audits, and providing comprehensive representation has with a high success rate.</p></div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <ul className='caserul'>
                                    <li><small>Transfer Pricing Documentation</small>
                                        <p>Excels in meticulous transfer pricing documentation, ensuring compliance and safeguarding clients from penalties.</p>
                                    </li>
                                    <li><small>Transfer Pricing Audit</small>
                                        <p>Expert representation protects clients from transfer pricing adjustments during audits.</p>
                                    </li>
                                    <li><small>Appellate Representation</small>
                                        <p>In the realm of transfer pricing, comprehensive study and skilled representation guarantees success before appellate bodies.</p>
                                    </li>
                                    <li className='exbtn'>
                                        <Link to={`${process.env.PUBLIC_URL + '/master-of-transfer'}`} className="expolink">Explore More</Link>
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
  </Accordion>*/}
                </div>
            </Container>
        </section>
        <NewEventSec/>

        {/* <Desclimerpopup show={desclimershow} onHide={() => setModalShow1(false)}/> */}

    </div>
  )
}

export default Home